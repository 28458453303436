import React, { useEffect, useState, useCallback } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useNavigate } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";

interface SessionTimeoutProps {
  isLoggedIn: boolean;
  timerKey: number; // Key to reset the session timer
}

const SessionTimeout: React.FC<
  SessionTimeoutProps & { onSessionTimeout: () => void }
> = ({ isLoggedIn, timerKey, onSessionTimeout }) => {
  const navigate = useNavigate();
  const [showTimeoutPopup, setShowTimeoutPopup] = useState(false);
  const [timer, setTimer] = useState<number>(Date.now());
  const sessionTimeoutLimit = 360000; // 6 minutes in milliseconds

  const handleLogout = useCallback(() => {
    localStorage.clear(); // Clear localStorage on logout
    setShowTimeoutPopup(true); // Show the timeout popup
    navigate("/"); // Redirect to home page
  }, [navigate]);

  const resetTimer = useCallback(() => {
    setTimer(Date.now()); // Reset the timer on user activity
  }, []);

  const closePopup = () => {
    setShowTimeoutPopup(false);
    // onSessionTimeout(); // Open login dialog
    if (window.location.pathname === "/") {
      window.location.reload();
    }
  };

  // Reset timer on user interaction, only if logged in
  useEffect(() => {
    if (isLoggedIn) {
      const events = [
        "mousemove",
        "mousedown",
        "keypress",
        "scroll",
        "touchstart",
      ];
      const resetEvents = () => resetTimer();
      events.forEach((event) => window.addEventListener(event, resetEvents));

      return () =>
        events.forEach((event) =>
          window.removeEventListener(event, resetEvents)
        );
    }
  }, [isLoggedIn, resetTimer]);

  // Session timeout effect, only if logged in
  useEffect(() => {
    if (isLoggedIn) {
      const intervalId = setInterval(() => {
        if (Date.now() - timer >= sessionTimeoutLimit) {
          handleLogout();
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [isLoggedIn, timer, sessionTimeoutLimit, handleLogout, timerKey]);

  return (
    <>
      {showTimeoutPopup && (
        <Dialog onClose={closePopup}>
          <div style={{ padding: "20px" }}>
            <h3>Your session has been terminated due to inactivity.</h3>
            <h5 style={{ textAlign: "center" }}>Please Log-In to continue</h5>
          </div>
          <DialogActionsBar>
            <Button
              className="k-button"
              style={{ color: "white", backgroundColor: "#42b677", marginLeft: "40%", marginRight:"40%" }}
              onClick={closePopup}
            >
              Okay
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
};

export default SessionTimeout;

// import React, { useEffect, useState, useCallback } from "react";
// import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
// import { useNavigate } from "react-router-dom";
// import { Button } from "@progress/kendo-react-buttons";

// interface SessionTimeoutProps {
//   isLoggedIn: boolean;
//   timerKey: number; // Key to reset the session timer
//   onSessionTimeout: () => void; // Callback to open login dialog
// }

// const SessionTimeout: React.FC<SessionTimeoutProps> = ({
//   isLoggedIn,
//   timerKey,
//   onSessionTimeout,
// }) => {
//   const navigate = useNavigate();
//   const [showTimeoutPopup, setShowTimeoutPopup] = useState(false);
//   const [timer, setTimer] = useState<number>(Date.now());
//   const sessionTimeoutLimit = 10000; // 6 minutes in milliseconds
//   const [loading, setLoading] = useState(false);

//   const handleLogout = useCallback(() => {
//     localStorage.clear();
//     setShowTimeoutPopup(true);
//     navigate("/");
//   }, [navigate]);

//   const resetTimer = useCallback(() => {
//     setTimer(Date.now());
//   }, []);
//   const closeSessionPopup = () => {
//     console.log("Close Session Popup");
//     setLoading(false);
//     setShowTimeoutPopup(false);
//     onSessionTimeout();
//   };

//   const closePopup = () => {
//     console.log("Close Popup");
//     setLoading(true);
//     if (window.location.pathname === "/") {
//       closeSessionPopup();
//     } else {
//       closeSessionPopup();
//     }
//   };

//   useEffect(() => {
//     if (isLoggedIn) {
//       const events = [
//         "mousemove",
//         "mousedown",
//         "keypress",
//         "scroll",
//         "touchstart",
//       ];
//       const resetEvents = () => resetTimer();
//       events.forEach((event) => window.addEventListener(event, resetEvents));

//       return () =>
//         events.forEach((event) =>
//           window.removeEventListener(event, resetEvents)
//         );
//     }
//   }, [isLoggedIn, resetTimer]);

//   useEffect(() => {
//     if (isLoggedIn) {
//       const intervalId = setInterval(() => {
//         if (Date.now() - timer >= sessionTimeoutLimit) {
//           handleLogout();
//         }
//       }, 1000);

//       return () => clearInterval(intervalId);
//     }

//     const timer1 = setTimeout(() => {
//       if (showTimeoutPopup) {
//         closePopup();
//       }
//     }, 2000);

//     return () => clearTimeout(timer1);
//   }, [
//     isLoggedIn,
//     timer,
//     sessionTimeoutLimit,
//     handleLogout,
//     timerKey,
//     showTimeoutPopup,
//   ]);

//   const handleOnOkClick = (e: any) => {
//     window.location.reload();
//     setShowTimeoutPopup(false);
//     e.preventDefault();
//     closePopup();
//   };

//   return (
//     <>
//       {showTimeoutPopup && (
//         <Dialog>
//           <div style={{ padding: "20px" }}>
//             <h3>Your session has been terminated due to inactivity.</h3>
//             <h5 style={{ textAlign: "center" }}>Please Log-In to continue</h5>
//           </div>
//           <DialogActionsBar>
//             <Button
//               className="button"
//               style={{
//                 color: "white",
//                 backgroundColor: "#42b677",
//                 width: "max-content",
//               }}
//               onClick={(e) => handleOnOkClick(e)}
//             >
//               Okay
//             </Button>
//           </DialogActionsBar>
//         </Dialog>
//       )}
//     </>
//   );
// };

// export default SessionTimeout;
