import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { commonBaseUrl, commonImageUrl } from "../services/CommonUtils";

interface FoodItem {
  id: number;
  name: string;
  image: string;
  description: string;
  maxQty: number;
  price: number;
  type: string;
  categoryId: number;
}

const FoodMenu = ({
  categoryId,
  onAddToCart,
}: {
  categoryId: number;
  onAddToCart: (item: FoodItem) => void;
}) => {
  const baseURL = commonBaseUrl();
  const imageURL = commonImageUrl();

  const [foodItems, setFoodItems] = useState<FoodItem[]>([]);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    getSubCategory();
  }, [categoryId]);

  // Function to check if current time is within the given time range
  const isCurrentTimeInRange = (fromTime: any, toTime: any) => {
    const currentDate = new Date();

    // Parse "from" and "to" times
    const [fromHour, fromMinute, fromSecond] = fromTime.split(":").map(Number);
    const [toHour, toMinute, toSecond] = toTime.split(":").map(Number);

    // Create date objects for comparison
    const fromDateTime = new Date(currentDate);
    fromDateTime.setHours(fromHour, fromMinute, fromSecond);

    const toDateTime = new Date(currentDate);
    toDateTime.setHours(toHour, toMinute, toSecond);

    // Check if current time is between "from" and "to" times
    return currentDate >= fromDateTime && currentDate <= toDateTime;
  };

  // Function to convert ISO date string to "HH:MM:SS" format
  const convertToTimeString = (isoDateString: any) => {
    // Parse the ISO string into a Date object
    const date = new Date(isoDateString);

    // Extract hours, minutes, and seconds
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    // Return the formatted time string
    return `${hours}:${minutes}:${seconds}`;
  };

  const getSubCategory = async () => {
    try {
      const response = await axios.get(
        `${baseURL}odata/SubCategorys?$filter=IsAvailable eq true`
      );
      if (response.data.value.length !== 0) {
        const subCategories = [];
        const responseData = response.data.value;
        for (let s = 0; s < responseData.length; s++) {
          const startTime = convertToTimeString(responseData[s].FromDate);
          const endTime = convertToTimeString(responseData[s].ToDate);
          if (
            isCurrentTimeInRange(startTime, endTime) === true &&
            responseData[s].Quantity > 0
          ) {
            const subCategorieData = {
              id: responseData[s].Id,
              name: responseData[s].Name,
              image: "",
              description: responseData[s].Description,
              maxQty: responseData[s].Quantity,
              price: responseData[s].Price,
              type: responseData[s].TypeId === 1 ? "Veg" : "Non-Veg",
              categoryId: responseData[s].CategoryId,
            };
            subCategories.push(subCategorieData);
          }
        }
        getSubCategoryImage(subCategories);
      }
    } catch (err) {
      alert(err);
    }
  };

  const getSubCategoryImage = async (responseData: any) => {
    try {
      const updatedSubImage = await Promise.all(
        responseData.map(async (item: any) => {
          const response = await axios.get(
            `${baseURL}odata/DocumentUploads?$filter=refno eq ${item.id} and contains(reftype, 'Food')`
          );
          if (response.data.value.length !== 0) {
            const fileURL: any =
              imageURL +
              response.data.value[response.data.value.length - 1].ImagePath;
            item.image = fileURL;
            return item;
          } else {
            item.image =
              "https://static.wixstatic.com/media/06bf14_78e47cfee3a04945a919da32dffd96c4~mv2.png/v1/crop/x_0,y_2,w_3000,h_664/fill/w_249,h_55,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/06bf14_78e47cfee3a04945a919da32dffd96c4~mv2.png";
            return item;
          }
        })
      );
      setFoodItems(updatedSubImage);
    } catch (err) {
      alert(err);
    }
  };

  const AllItems = foodItems.filter((item) => item.categoryId === categoryId);

  // Filter food items based on selected category and type (Veg or Non-Veg)
  const vegItems = foodItems.filter(
    (item) => item.categoryId === categoryId && item.type === "Veg"
  );
  const nonVegItems = foodItems.filter(
    (item) => item.categoryId === categoryId && item.type === "Non-Veg"
  );

  const handleSelect = (e: any) => {
    setSelectedTab(e.selected); // Updates the selected tab index
  };

  return (
    <div>
      <TabStrip selected={selectedTab} onSelect={handleSelect}>
        <TabStripTab title="All">
          <div className="food-items">
            {AllItems.length > 0 ? (
              AllItems.map((item) => (
                <div key={item.id} className="food-card">
                  <div>
                    <img src={item.image} className="food-listview-image" />
                  </div>
                  <div className="food-direction">
                    <div className="food-details">
                      <Label className="order-name">{item.name}</Label>
                      <Label className="order-description">
                        {item.description}
                      </Label>
                    </div>
                    <div className="food-price">
                      <span className="order-price">
                        ₹{item.price.toFixed(2)}
                      </span>
                      <Button onClick={() => onAddToCart(item)}>Add</Button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No items available.</p>
            )}
          </div>
        </TabStripTab>
        <TabStripTab title="Vegetarian">
          <div className="food-items">
            {vegItems.length > 0 ? (
              vegItems.map((item) => (
                <div key={item.id} className="food-card">
                  <div>
                    <img src={item.image} className="food-listview-image" />
                  </div>
                  <div className="food-direction">
                    <div className="food-details">
                      <Label className="order-name">{item.name}</Label>
                      <Label className="order-description">
                        {item.description}
                      </Label>
                    </div>
                    <div className="food-price">
                      <span className="order-price">
                        ₹{item.price.toFixed(2)}
                      </span>
                      <Button onClick={() => onAddToCart(item)}>Add</Button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No Vegetarian items available.</p>
            )}
          </div>
        </TabStripTab>
        <TabStripTab title="Non-Vegetarian">
          <div className="food-items">
            {nonVegItems.length > 0 ? (
              nonVegItems.map((item) => (
                <div key={item.id} className="food-card">
                  <div>
                    <img src={item.image} className="food-listview-image" />
                  </div>
                  <div className="food-direction">
                    <div className="food-details">
                      <Label className="order-name">{item.name}</Label>
                      <Label className="order-description">
                        {item.description}
                      </Label>
                    </div>
                    <div className="food-price">
                      <span className="order-price">
                        ₹{item.price.toFixed(2)}
                      </span>
                      <Button onClick={() => onAddToCart(item)}>Add</Button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No Non-Vegetarian items available.</p>
            )}
          </div>
        </TabStripTab>
      </TabStrip>
    </div>
  );
};

export default FoodMenu;
